html, body{ 
  width: 100%; 
  padding: 0; 
  margin: 0; 
  background-image: linear-gradient(#111111,rgb(10, 0, 27));

}

@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&family=Ubuntu+Condensed&display=swap');
@import url('https://cdn.rawgit.com/konpa/devicon/df6431e323547add1b4cf45992913f15286456d3/devicon.min.css');
